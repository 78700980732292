/** Used to generate unique numbers to append to id and aria attributes in reusable components */
/* Based on https://github.com/lodash/lodash/blob/master/uniqueId.js */

const idCounter = {};

/**
 * Generates a unique ID. If `prefix` is given, the ID is appended to it.
 *
 * @since 0.1.0
 * @category Util
 * @param {string} [prefix=''] The value to prefix the ID with.
 * @returns {string} Returns the unique ID.
 * @see random
 * @example
 *
 * componentId('contact_')
 * // => 'contact_104'
 *
 * componentId()
 * // => '105'
 */
export const generateComponentId = (prefix = '') => {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0;
  }

  idCounter[prefix] += 1;
  const id = idCounter[prefix];
  if (prefix === '') {
    return `${id}`;
  }

  return `${prefix}${id}`;
};
