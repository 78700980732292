import { getPreAuthedHistoryClient } from '@ax/data-services-history/client';
import {
  ApiClientError,
  AxiosResponseError,
  GlobalPaginationResponse,
} from '@ax/api-clients-common/models';
import {
  AxiosQuery,
  defaultCatchHandler,
} from '@ax/data-services-common/queries';
import {
  makeQueryable,
  QueryStatusErrorFromQuery,
  UnknownFetchError,
} from '@ax/cache-and-dedupe-vue';
import { success } from '@ax/type-utils';
import {
  showErrorNotification,
  showStandardHttpErrorNotification,
} from '@ax/notifications';
import {
  PolicyResultsDTO,
  PolicyResultsList,
  PolicyResults,
  PolicyResultsDevicesDTO,
  PolicyResultsDevices,
} from '@/models/policy-results';
import { JwtMintingError } from '@ax/data-services-authentication/models';
import { flatMap } from '@ax/function-utils/request';
import { ConfigLoadingError } from '@ax/data-services-config';
import { TimeoutError } from '@ax/function-utils';

export const REPORTS_POLICIES = 'reports/policies';

/**
 * Describes a query for the list of policy results based on the provided organization id and query params
 * @param zoneId The id of the zone to which the policy belongs.
 * @param query The url query param used for filtering, sorting, and other such functionality.
 * */
export class GetPolicyResultsList extends AxiosQuery<
  { readonly zoneId: string; readonly query: string },
  PolicyResultsList,
  ApiClientError<AxiosResponseError> | JwtMintingError | ConfigLoadingError
> {
  readonly type = 'GetPolicyResultsList';
}

/**
 * Describes a query for  the policy result for a single policy based on the provided organization id and query params
 * @param zoneId The id of the zone to which the policy belongs.
 * @param policyUuid The UUID of the policy to be queried
 * */
export class GetPolicyResults extends AxiosQuery<
  { readonly zoneId: string; readonly policyUuid: string },
  PolicyResults,
  ApiClientError<AxiosResponseError> | JwtMintingError | ConfigLoadingError
> {
  readonly type = 'GetPolicyResult';
}

/**
 * Describes a query for the list of policy result details broken down by device for a single policy
 * based on the provided organization id and query params
 * @param zoneId The id of the zone to which the policy belongs.
 * @param policyUuid The UUID of the policy to be queried
 * @param query The url query param used for filtering, sorting, and other such functionality.
 * */
export class GetPolicyResultsDevices extends AxiosQuery<
  {
    readonly zoneId: string;
    readonly policyUuid: string;
    readonly query: string;
  },
  PolicyResultsDevices,
  ApiClientError<AxiosResponseError> | JwtMintingError | ConfigLoadingError
> {
  readonly type = 'GetPolicyResultsDevices';
}

export function showNotificationOnFailure(
  failure:
    | ApiClientError<AxiosResponseError>
    | JwtMintingError
    | ConfigLoadingError
    | TimeoutError
    | UnknownFetchError,
) {
  if (failure.type === 'ApiClientError') {
    showStandardHttpErrorNotification(failure.error);
  } else if (failure.type === 'TimeoutError') {
    showErrorNotification(failure.message);
  } else if (failure.type === 'JwtMintingError') {
    showStandardHttpErrorNotification();
  } else if (failure.type === 'ConfigLoadingError') {
    showStandardHttpErrorNotification();
  }
}

function onFailure(
  failure:
    | QueryStatusErrorFromQuery<GetPolicyResultsList>
    | QueryStatusErrorFromQuery<GetPolicyResultsDevices>,
) {
  showNotificationOnFailure(failure);
}

/**
 * Gets the list of policy results based on the provided organization id and query params.
 * @param request The request describing the `zoneId` and `query` params.
 * @returns The list of policy results as a promise.
 * */
export const fetchPolicyResultsList = (request: GetPolicyResultsList) => {
  // fetch Jwt, then check if the token type is 'success' using `flatMap` before
  // making the service request
  return getPreAuthedHistoryClient().then(
    flatMap((client) =>
      client
        .get<GlobalPaginationResponse<PolicyResultsDTO>>(
          `/${request.zoneId}/${REPORTS_POLICIES}?${request.query}`,
          {
            loaderEnabled: request.showLoader,
          },
        )
        .then(({ data }) => success(PolicyResultsList.fromDTO(data)))
        .catch(defaultCatchHandler),
    ),
  );
};

export const {
  useQuery: useGetPolicyResultsListQuery,
  runQuery: runGetPolicyResultsListQuery,
} = makeQueryable(fetchPolicyResultsList, {
  hooks: {
    onFailure,
  },
});

/**
 * Gets the policy result for a single policy based on the provided organization id and query params
 * @param request The request describing the `zoneId` and `policyId` params
 * @returns A single policy result set as a promise
 * */
export const fetchPolicyResults = (request: GetPolicyResults) => {
  // fetch Jwt, then check if the token type is 'success' using `flatMap` before
  // making the service request
  return getPreAuthedHistoryClient().then(
    flatMap((client) =>
      client
        .get<PolicyResultsDTO>(
          `/${request.zoneId}/${REPORTS_POLICIES}/${request.policyUuid}`,
          {
            loaderEnabled: request.showLoader,
          },
        )
        .then(({ data }) => success(PolicyResults.fromDTO(data)))
        .catch(defaultCatchHandler),
    ),
  );
};

export const {
  useQuery: useGetPolicyResultsQuery,
  runQuery: runGetPolicyResultsQuery,
} = makeQueryable(fetchPolicyResults, {
  hooks: {
    onFailure,
  },
});

/**
 * Gets the list of policy result details broken down by device for a single policy
 * based on the provided organization id and query params
 * @param request The request describing the `zoneId`, `policyId`, and `query` params
 * @returns The list of policy result details as a promise
 * */
export const fetchPolicyResultsDevices = (request: GetPolicyResultsDevices) => {
  // fetch Jwt, then check if the token type is 'success' using `flatMap` before
  // making the service request
  return getPreAuthedHistoryClient().then(
    flatMap((client) =>
      client
        .get<PolicyResultsDevicesDTO>(
          `/${request.zoneId}/${REPORTS_POLICIES}/${request.policyUuid}/devices?${request.query}`,
          {
            loaderEnabled: request.showLoader,
          },
        )
        .then((result) => {
          return success(PolicyResultsDevices.fromDTO(result.data));
        })
        .catch(defaultCatchHandler),
    ),
  );
};

export const {
  useQuery: useGetPolicyResultsDevicesQuery,
  runQuery: runGetPolicyResultsDevicesQuery,
} = makeQueryable(fetchPolicyResultsDevices, {
  hooks: {
    onFailure,
  },
});

/**
 * Internal helper to download a CSV file from the given URL. This can't be done in a regular
 * href since we are needing the JWT token.
 *
 * @param url The URL to retrieve.
 * @param defaultFileName A default file name if we can't get it from the headers.
 */
function downloadCsvFile(url, defaultFileName) {
  // fetch Jwt, then check if the token type is 'success' using `flatMap` before
  // making the service request
  return getPreAuthedHistoryClient().then(
    flatMap((client) =>
      client
        .get<Blob>(url, {
          responseType: 'blob',
        })
        .then((response) => {
          const contentDisposition = response.headers['content-disposition'];
          let fileName = defaultFileName;
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch && fileNameMatch.length === 2) {
              // eslint-disable-next-line prefer-destructuring
              fileName = fileNameMatch[1];
            }
          }

          const link = document.createElement('a');
          const url = URL.createObjectURL(response.data);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return success();
        })
        .catch(defaultCatchHandler),
    ),
  );
}

/**
 * Downloads the csv for the summary of recent policy runs.
 * @param zoneId The id of the zone to which the policy belongs.
 * @returns A promise for tracking the status.
 */
export function downloadPolicyResultsCsv(zoneId: string) {
  return downloadCsvFile(
    `/${zoneId}/${REPORTS_POLICIES}/csv`,
    `policy_results_${zoneId}.csv`,
  );
}

/**
 * Downloads the csv for details of a policy execution.
 * @param zoneId The id of the zone to which the policy belongs.
 * @param policyUuid The UUID of the policy of which the details we are retrieving.
 * @returns A promise for tracking the status.
 */
export function downloadPolicyResultDetailsCsv(
  zoneId: string,
  policyUuid: string,
) {
  return downloadCsvFile(
    `/${zoneId}/${REPORTS_POLICIES}/${policyUuid}/devices/csv`,
    `policy_results_${zoneId}_${policyUuid}.csv`,
  );
}
