import { formatUTCToZonedTime } from '@ax/date-time-utils';
import {
  Policy,
  PolicyConfiguration,
  PrimaryPolicyTypes,
  PolicyTypes,
  PolicyTypeQueryParams,
  PolicyFilterTypes,
  PolicyPatchRule,
} from '@/models/policy';
import { parseMilitaryTime, bitwiseDate } from '@/utils/date-time';
import { combineText } from '@/utils/display-text';
import { consoleI18n } from '@ax/console-i18n';

const generateDaySchedule = (policy: Policy): string => {
  const { schedule_days = 0 } = policy;
  const scheduleDays = bitwiseDate(schedule_days, 'days');

  if (scheduleDays.length === 7) {
    return 'Every day';
  }

  if (scheduleDays.length > 0) {
    return `Every ${combineText(scheduleDays)}`;
  }

  return '';
};

const generateWeekSchedule = (policy: Policy): string => {
  const { schedule_weeks_of_month = 0 } = policy;
  const scheduleWeeksOfMonth = bitwiseDate(schedule_weeks_of_month, 'weeks');

  if (scheduleWeeksOfMonth.length === 5) {
    return ` every week`;
  }

  if (scheduleWeeksOfMonth.length > 0) {
    return ` on the ${combineText(scheduleWeeksOfMonth)} week`;
  }

  return '';
};

const generateMonthSchedule = (policy: Policy): string => {
  const { schedule_months = 0 } = policy;
  const scheduleMonths = bitwiseDate(schedule_months, 'months');

  if (scheduleMonths.length === 12) {
    return 'of every month';
  }

  if (scheduleMonths.length > 0) {
    return `of ${combineText(scheduleMonths)}`;
  }

  return '';
};

export const getPolicyScheduleText = (policy?: Policy): string => {
  if (policy) {
    const days = generateDaySchedule(policy);
    const weeks = generateWeekSchedule(policy);
    const months = generateMonthSchedule(policy);

    if (days && weeks && months) {
      return `${days} ${weeks} ${months}`;
    }
  }

  return '';
};

export const getNextPatchWindow = (policy: Policy): null | string => {
  const { policy_type_name, configuration } = policy;
  const isOff =
    policy_type_name === 'patch' && configuration && !configuration.auto_patch;

  if (!isOff && policy.next_remediation) {
    return formatUTCToZonedTime(policy.next_remediation) || '-';
  }

  return null;
};

export const nextPolicyWindow = (policy: Policy): string => {
  if (policy.next_remediation) {
    return formatUTCToZonedTime(policy.next_remediation) || '-';
  }

  return '-';
};

export const getScheduleTimeText = (policy: Policy): string => {
  const { schedule_time = '', configuration } = policy;
  const parsedTime = schedule_time ? parseMilitaryTime(schedule_time) : '';
  const timezone = configuration?.scheduled_timezone || 'UTC+0000';
  const offset = configuration?.use_scheduled_timezone
    ? timezone
    : 'local time of device';
  const scheduled =
    generateDaySchedule(policy) &&
    generateWeekSchedule(policy) &&
    generateMonthSchedule(policy);

  return scheduled ? `${parsedTime} ${offset}` : '';
};

export const getServerGroupCount = (serverGroups): number => {
  return serverGroups ? serverGroups.length : 0;
};

export const getAutoPatchUsage = (policy: Policy): string => {
  if (policy.policy_type_name === 'patch') {
    return policy.configuration!.auto_patch
      ? consoleI18n.t('general.statuses.active')
      : consoleI18n.t('general.statuses.inactive');
  }

  return '-';
};

export const getPolicyTypeName = (policy: Policy): string => {
  const { policy_type_name = PrimaryPolicyTypes.patch } = policy;

  if (policy_type_name === 'custom') {
    return 'Worklet';
  }
  if (policy_type_name === 'required_software') {
    return 'Required Software';
  }

  const policyType = getPolicyEnumFromConfig(policy);

  switch (policyType) {
    case 'patch_all_except':
      return 'Patch All Except';
    case 'patch_only':
      return 'Patch Only';
    case 'patch_manual_approval':
      return 'Manual Approval';
    case 'patch_by_severity':
      return 'By Severity';
    case 'patch_advanced':
      return 'Advanced';
    case 'patch_all':
    default:
      return 'Patch All';
  }
};

export const getDeviceFiltersValue = (policy: Policy): boolean => {
  return !!(
    policy.configuration?.device_filters_enabled &&
    policy.configuration.device_filters &&
    policy.configuration.device_filters?.length > 0
  );
};

export const POLICY_TYPES = {
  patch: 'Patch',
  required_software: 'Required Software',
  custom: 'Worklet',
};

export const PATCH_TYPES = [
  { name: 'Patch All', param: PolicyTypeQueryParams.patch_all },
  { name: 'Patch Only', param: PolicyTypeQueryParams.patch_only },
  { name: 'Patch Except', param: PolicyTypeQueryParams.patch_all_except },
  {
    name: 'Manual Approval',
    param: PolicyTypeQueryParams.patch_manual_approval,
  },
  { name: 'By Severity', param: PolicyTypeQueryParams.patch_by_severity },
  { name: 'Advanced', param: PolicyTypeQueryParams.patch_advanced },
];

export const getPolicyType = (policy: Policy): string => {
  const { policy_type_name = 'patch' } = policy;
  return POLICY_TYPES[policy_type_name];
};

export const getPolicyEnumFromConfig = (policy: Policy): string | null => {
  const { filter_type, patch_rule } =
    policy.configuration as PolicyConfiguration;

  if (
    filter_type === PolicyFilterTypes.all &&
    patch_rule === PolicyPatchRule.all
  ) {
    return 'patch_all';
  }
  if (
    filter_type === PolicyFilterTypes.exclude &&
    patch_rule === PolicyPatchRule.filter
  ) {
    return 'patch_all_except';
  }
  if (
    filter_type === PolicyFilterTypes.include &&
    patch_rule === PolicyPatchRule.filter
  ) {
    return 'patch_only';
  }
  if (
    filter_type === PolicyFilterTypes.all &&
    patch_rule === PolicyPatchRule.manual
  ) {
    return 'patch_manual_approval';
  }
  if (
    filter_type === PolicyFilterTypes.severity &&
    patch_rule === PolicyPatchRule.filter
  ) {
    return 'patch_by_severity';
  }
  if (
    filter_type === PolicyFilterTypes.all &&
    patch_rule === PolicyPatchRule.advanced
  ) {
    return 'patch_advanced';
  }

  return null;
};

export const getPolicyTitleByParam = (
  type: PolicyTypeQueryParams | null,
): PolicyTypes | null => {
  switch (type) {
    case PolicyTypeQueryParams.patch_all:
      return PolicyTypes.patch_all;
    case PolicyTypeQueryParams.patch_all_except:
      return PolicyTypes.patch_all_except;
    case PolicyTypeQueryParams.patch_only:
      return PolicyTypes.patch_only;
    case PolicyTypeQueryParams.patch_manual_approval:
      return PolicyTypes.patch_manual_approval;
    case PolicyTypeQueryParams.patch_by_severity:
      return PolicyTypes.patch_by_severity;
    case PolicyTypeQueryParams.patch_advanced:
      return PolicyTypes.patch_advanced;
    default:
      return null;
  }
};

export const getPolicyConfigurationFromParam = (
  type: PolicyTypeQueryParams | null,
): Partial<PolicyConfiguration> => {
  switch (type) {
    case PolicyTypeQueryParams.patch_all_except:
      return {
        filter_type: PolicyFilterTypes.exclude,
        patch_rule: PolicyPatchRule.filter,
      };
    case PolicyTypeQueryParams.patch_only:
      return {
        filter_type: PolicyFilterTypes.include,
        patch_rule: PolicyPatchRule.filter,
      };
    case PolicyTypeQueryParams.patch_manual_approval:
      return {
        filter_type: PolicyFilterTypes.all,
        patch_rule: PolicyPatchRule.manual,
      };
    case PolicyTypeQueryParams.patch_by_severity:
      return {
        filter_type: PolicyFilterTypes.severity,
        patch_rule: PolicyPatchRule.filter,
      };
    case PolicyTypeQueryParams.patch_advanced:
      return {
        filter_type: PolicyFilterTypes.all,
        patch_rule: PolicyPatchRule.advanced,
      };
    case PolicyTypeQueryParams.patch_all:
    default:
      return {
        filter_type: PolicyFilterTypes.all,
        patch_rule: PolicyPatchRule.all,
      };
  }
};

export const formatPolicyPackageSearch = (search: string): string =>
  `*${search}*`;
export const cleanPolicyPackageSearch = (search: string): string =>
  search.slice(1, -1);
export const isPolicyPackageSearch = (search: string): boolean =>
  search.startsWith('*') && search.endsWith('*');

export const policyHelpText = {
  patchAll:
    'This installs all available updates for supported software every time the policy is run.',
  patchAllExcept:
    'This installs all available updates for supported software, but it allows the administrator to block packages by name. Selected packages will not be installed.',
  patchOnly:
    'This only installs the packages that have been selected by the administrator.',
  manualApprove:
    'No packages are installed unless they have been approved by an administrator. Packages that are pending approval show up on the Packages Ready For Approval page.',
  bySeverity:
    'Only policies that match approved severity ratings are installed.',
  advanced: 'Only packages that meet all of the criteria will be patched.',
  required_software:
    'Required Software Policies will patch and install software that you designate.',
  worklet:
    'Worklets can perform any scriptable action on devices, including disabling a vulnerable process, managing native OS controls, mass rollback of patches, removing unwanted applications, and many other system configurations.',
};
