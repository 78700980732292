/**
 * Returns a promise that resolves (void) after a vuelidate validation
 * is no longer $pending
 *
 * @remarks
 * https://github.com/vuelidate/vuelidate/issues/179
 * Vuelidate doesn't have a built-in way to wait for a pending
 * async validation to resolve, before, say, submitting a form.
 * This provides a way for us to wait on a given validation.
 *
 * @param validation The async vuelidate validation that is pending
 * @param timeout The maximum amount of time in ms to wait before rejecting the promise
 * @returns Promise<void>
 */
export function pollPendingValidation(
  validation: { $pending: Boolean },
  timeout?: number,
): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!validation.$pending) {
      resolve();
    }

    // Poll for validation to no longer be $pending
    const pollInterval = setInterval(() => {
      if (!validation.$pending) {
        clearInterval(pollInterval);
        resolve();
      }
    }, 250);

    // Maximum time we're going to wait
    if (timeout) {
      setTimeout(() => {
        clearInterval(pollInterval);
        reject(new Error(`Timed out after ${timeout}ms`));
      }, timeout);
    }
  });
}
