const pluralize = (
  count = 0,
  singular: string,
  plural: string,
  includeNumber = true,
): string => {
  if (includeNumber) {
    return `${count} ${count === 1 ? singular : plural}`;
  }

  return count === 1 ? singular : plural;
};

export default pluralize;
