import { DataTablePageState } from '@ax/data-table-state';
import { Filter, FilterKeys, StandardSort } from '@ax/url-query-state';
import { AxiosResponse } from 'axios';
import { PaginatedResponse } from '@/models/pagination';
import {
  TaskAction,
  TaskBatch,
  TaskBatchIssue,
  TaskBatchSingle,
  TaskBatchStatus,
  TaskBatchType,
  TaskDetails,
  TaskDetailsResponse,
  TaskDetailsType,
  TaskIssues,
} from '@/models/task';
import httpClient from './http-client';

export const TASKS_ENDPOINT = 'tasks';
export const TASKS_BATCHES_ENDPOINT = 'tasks/batches';

type GetBatchesFilters = FilterKeys<'id', Filter.Equals, number> &
  FilterKeys<'type', Filter.Equals, TaskBatchType> &
  FilterKeys<'status', Filter.In | Filter.NotIn, TaskBatchStatus[]>;

type GetBatchesSort = StandardSort<
  'id' | 'impacted_device_count' | 'source' | 'status' | 'updated_at'
>;

export type GetBatchesQuery = GetBatchesFilters &
  GetBatchesSort &
  DataTablePageState<TaskBatch>;

export function getBatches(
  orgId: number,
  query: GetBatchesQuery,
): Promise<PaginatedResponse<TaskBatch>> {
  return httpClient
    .get(`/orgs/${orgId}/${TASKS_BATCHES_ENDPOINT}`, { params: query })
    .then(({ data }) => data);
}

export function getBatch(
  orgId: number,
  batchId: number,
): Promise<TaskBatchSingle> {
  return httpClient
    .get(`/orgs/${orgId}/${TASKS_BATCHES_ENDPOINT}/${batchId}`, {
      params: { o: orgId },
    })
    .then(({ data }) => data);
}

type GetBatchTasksFilters = FilterKeys<'type', Filter.In, TaskDetailsType[]>;

export type GetBatchTasksQuery = GetBatchTasksFilters &
  DataTablePageState<TaskDetails>;

export function getBatchTasks(
  orgId: number,
  batchId: number,
  query: Partial<GetBatchTasksQuery>,
): Promise<TaskDetailsResponse> {
  return httpClient
    .get(`/orgs/${orgId}/${TASKS_BATCHES_ENDPOINT}/${batchId}/tasks`, {
      params: query,
    })
    .then(({ data }) => data);
}

type GetBatchIssuesFilters = FilterKeys<
  'issues',
  Filter.In,
  (
    | TaskIssues.UnsupportedHost
    | TaskIssues.UnknownCVE
    | TaskIssues.UnknownHost
  )[]
>;

export type GetBatchIssuesQuery = GetBatchIssuesFilters &
  DataTablePageState<TaskBatchIssue>;

export function getBatchIssues(
  orgId: number,
  batchId: number,
  query: GetBatchIssuesQuery,
): Promise<PaginatedResponse<TaskBatchIssue>> {
  return httpClient
    .get(`/orgs/${orgId}/${TASKS_BATCHES_ENDPOINT}/${batchId}/issues`, {
      params: query,
    })
    .then(({ data }) => data);
}

export function initiateTaskAction(
  orgId: number,
  taskId: number,
  action: TaskAction,
): Promise<AxiosResponse> {
  const query = { o: orgId };

  return httpClient
    .patch(
      `/orgs/${orgId}/${TASKS_ENDPOINT}/${taskId}`,
      { action },
      { params: query },
    )
    .then(({ data }) => data);
}

export function executeTaskWithWorklet(
  orgId: number,
  taskId: number,
  policyId: number,
): Promise<AxiosResponse> {
  const query = { o: orgId };

  return httpClient
    .post(
      `/orgs/${orgId}/${TASKS_ENDPOINT}/${taskId}/execute-worklet`,
      { policy_id: policyId },
      { params: query },
    )
    .then(({ data }) => data);
}

export function acceptBatch(
  orgId: number,
  batchId: number,
): Promise<AxiosResponse> {
  const query = { o: orgId };

  return httpClient
    .post(
      `/orgs/${orgId}/${TASKS_BATCHES_ENDPOINT}/${batchId}/accept`,
      {},
      { params: query },
    )
    .then(({ data }) => data);
}
