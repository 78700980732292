import { computed, ref } from '@vue/composition-api';
import { AccountRbacRoleDTO } from '@ax/api-clients-accounts/models';

// State
const _roles = ref<AccountRbacRoleDTO[]>([]);

// Getters
export const roles = computed(() => _roles.value);

// Setters
export function setRoles(roles: AccountRbacRoleDTO[]): void {
  _roles.value = roles;
}
