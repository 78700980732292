import { User } from '@ax/data-services-user/models/user';
import { AppStore } from '@/store';
import { USER_GET_PROFILE } from '@/store/actions/user';

/**
 * Loops through the list of organizations for a given user to find the
 * zone uuid that corresponds with the current org id.
 * Returns an empty string if:
 *  - the found zone id is somehow an empty string OR
 *  - if NO matching organization was found.
 * @param orgId The organization id used to find the zone id.
 * @returns The zone uuid that corresponds with the current org id.
 */
export function getZoneId(orgId: number): string {
  const user = AppStore.getters[USER_GET_PROFILE] as User;
  const { orgs } = user;

  return orgs.find((org) => org.id === orgId)?.zone_id || '';
}
