import { ExportColumn as NewExportColumn } from '@ax/cosmos/components/Table/table.models';
import { isNullable } from '@ax/function-utils/nullable';
import { prop } from '@ax/object-utils';

/** @deprecated DON'T USE, instead use:  import { ExportColumn } from '@ax/cosmos/components/Table'; */
export type ExportColumn = NewExportColumn;

const SEPARATOR = ',';

type ToData<Row> = (row: Row) => string;

export type RowReplacers<Row> = {
  [K in keyof Row]?: ToData<Row>;
};

function convertColumn<Row>(row: Row, replacers?: RowReplacers<Row>) {
  return (column: NewExportColumn) => {
    const cellData = row[column.id];

    if (replacers && replacers[column.id]) {
      return `"${replacers[column.id](row)}"`;
    }

    if (Array.isArray(cellData)) {
      return `"${cellData.join(' ').replace(/"/g, "'")}"`;
    }

    return isNullable(cellData) ? '' : `"${cellData}"`;
  };
}

function convertRow<Row>(
  columns: readonly NewExportColumn[],
  replacers?: RowReplacers<Row>,
) {
  return (row: Row) =>
    columns.map(convertColumn(row, replacers)).join(SEPARATOR);
}

/**
 * Convert a data table into a CSV string
 * @param data The rows of data of type `Row`
 * @param columns The columns of the table
 * @param replacers Functions that map row keys to strings for custom column stringification
 * @returns a string representing the table converted to a csv.
 */
export function generateCSV<Row>(
  data: readonly Row[],
  columns: readonly NewExportColumn[],
  replacers?: RowReplacers<Row>,
): string {
  const csvHeader = columns.map(prop('name')).join(SEPARATOR);
  const csvContent = data.map(convertRow(columns, replacers)).join('\n');

  return [csvHeader, csvContent].join('\n');
}

/**
 * Use to convert a table into a CSV and download the csv.
 * @param data The rows to convert to a csv
 * @param columns The columns to extract out of the `data`
 * @param filename The filename to use for the download link
 * @param replacers Functions that map row keys to strings for custom column stringification
 * @returns a `Promise<void>` that resolves when the csv is downloaded
 */
export function generateAndDownloadCSV<Row>(
  data: readonly Row[],
  columns: readonly NewExportColumn[],
  filename: string,
  replacers?: RowReplacers<Row>,
): Promise<void> {
  const csvContent = generateCSV(data, columns, replacers);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  return new Promise((resolve, reject) => {
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve();
    } else {
      reject();
    }
  });
}
