import axios, {
  AxiosResponse,
  CancelTokenSource,
  AxiosRequestConfig,
} from 'axios';
import { userTimeZone } from '@ax/date-time-utils';
import {
  stringifyUrlQuery,
  removeEmptyEntriesFromShallowObject,
} from '@/utils/util';

import { AxFile } from '@/models/file';
import { Policy, PolicyPreview, PolicySchedule } from '@/models/policy';
import { PackagesApprovalResponse } from '@/models/packageApproval';
import {
  PolicyDeviceFilterPreviewResponse,
  PolicyDeviceFilter,
} from '@/models/policy-device-filter';

import httpClient from './http-client';

const POLICIES_ENDPOINT = '/policies';
const POLICIES_SCHEDULE_ENDPOINT = '/policyschedule';
const POLICIES_APPROVAL_ENDPOINT = '/approvals';
const POLICY_PREVIEW = '/policypreview';
const POLICY_DEVICE_FILTERS_ENDPOINT = '/policies/device-filters-preview';

export function getPolicies(
  orgId: number,
  query: string = stringifyUrlQuery({ o: orgId, tz: userTimeZone }),
): Promise<Policy[]> {
  return httpClient
    .get(`${POLICIES_ENDPOINT}?${query}`)
    .then(({ data }) => data);
}

export function getPolicy(
  policyId: string | number,
  orgId: string | number,
): Promise<Policy> {
  return httpClient
    .get(`${POLICIES_ENDPOINT}/${policyId}?${stringifyUrlQuery({ o: orgId })}`)
    .then(({ data }) => new Policy(data));
}

export function getPolicySchedule(
  orgId: string | number,
  startDate: string,
  endDate: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  policyTimes: Partial<Record<string, any>>,
): Promise<PolicySchedule[]> {
  return httpClient
    .get(
      `${POLICIES_SCHEDULE_ENDPOINT}?${stringifyUrlQuery({
        o: orgId,
        startDate,
        endDate,
        ...policyTimes,
      })}`,
      { loaderEnabled: false } as AxiosRequestConfig,
    )
    .then(({ data }) => data);
}

export function runPolicy(
  orgId: string | number,
  policyId: string | number,
  deviceId?: string | number,
  action = 'remediateServer',
): Promise<AxiosResponse> {
  const payload = removeEmptyEntriesFromShallowObject({
    action,
    serverId: deviceId,
  });

  return httpClient
    .post(
      `${POLICIES_ENDPOINT}/${policyId}/action?${stringifyUrlQuery({
        o: orgId,
      })}`,
      payload,
    )
    .then(({ data }) => data);
}

export function createPolicy(policy: Policy): Promise<Policy> {
  const { organization_id } = policy;

  return (
    httpClient
      .post(
        `${POLICIES_ENDPOINT}?${stringifyUrlQuery({
          o: organization_id,
          internal: 1,
        })}`,
        policy,
      )
      // TODO: API Upgrade - The POST response should just return the new policy, not a location header
      .then((response) => {
        return response.headers.location.split('/').pop();
      })
      .then((id) => getPolicy(id, String(organization_id)))
  );
}

export function updatePolicy(policy: Policy): Promise<AxiosResponse> {
  const { id, organization_id } = policy;

  return httpClient.put(
    `${POLICIES_ENDPOINT}/${id}?${stringifyUrlQuery({
      o: organization_id,
      internal: 1,
    })}`,
    policy,
  );
}

export function deletePolicy(policy: Policy): Promise<AxiosResponse> {
  const { id, organization_id } = policy;

  return httpClient.delete(
    `${POLICIES_ENDPOINT}/${id}?${stringifyUrlQuery({
      o: organization_id,
      internal: 1,
    })}`,
  );
}

export function getPolicyFiles(policy: Policy): Promise<AxFile[]> {
  const { id, organization_id } = policy;
  return httpClient
    .get(
      `${POLICIES_ENDPOINT}/${id}/files?${stringifyUrlQuery({
        o: organization_id,
      })}`,
      {
        loaderEnabled: false,
      } as AxiosRequestConfig,
    )
    .then(({ data }) => data);
}

export function createPolicyFile(
  policy: Policy,
  file: File,
  onUploadProgress: (event: ProgressEvent) => void,
): [CancelTokenSource, Promise<AxiosResponse<AxFile>>] {
  const { id, organization_id } = policy;
  const cancelToken = axios.CancelToken.source();

  const formData = new FormData();
  formData.append('file', file);

  return [
    cancelToken,
    httpClient.post(
      `${POLICIES_ENDPOINT}/${id}/files?${stringifyUrlQuery({
        o: organization_id,
      })}`,
      formData,
      {
        onUploadProgress,
        cancelToken: cancelToken.token,
        loaderEnabled: false,
      } as AxiosRequestConfig,
    ),
  ];
}

// TODO: Remove if worklets gets merged in first
export function deletePolicyFile(
  policy: Policy,
  fileData: { index: number; file: AxFile },
): Promise<AxiosResponse> {
  const { id, organization_id } = policy;

  return httpClient.delete(
    `${POLICIES_ENDPOINT}/${id}/files/${fileData.file.id}?${stringifyUrlQuery({
      o: organization_id,
      internal: 1,
    })}`,
  );
}

export function approvePolicyPackage(
  policyApprovalId: number,
  orgId: string | number,
  payload,
): Promise<void> {
  return httpClient.put(
    `${POLICIES_APPROVAL_ENDPOINT}/${policyApprovalId}?${stringifyUrlQuery({
      o: orgId,
      internal: 1,
    })}`,
    {
      ...payload,
    },
  );
}

export function getPolicySoftwareVersion(
  query: string,
): Promise<PackagesApprovalResponse> {
  return httpClient
    .get(`${POLICIES_APPROVAL_ENDPOINT}?${query}`)
    .then(({ data }) => data);
}

export function postPolicyPreview(
  policy: Policy,
  query: string,
): Promise<PolicyPreview> {
  return httpClient
    .post(`${POLICY_PREVIEW}?${query}`, policy)
    .then((response) => response.data);
}

export const postPolicyDeviceFilters = (
  payload: { server_groups: number[]; device_filters: PolicyDeviceFilter[] },
  query: string,
): Promise<PolicyDeviceFilterPreviewResponse> => {
  return httpClient
    .post(`${POLICY_DEVICE_FILTERS_ENDPOINT}?${query}`, payload)
    .then((response) => {
      return new PolicyDeviceFilterPreviewResponse(
        response.data.results,
        response.data.size,
      );
    });
};
