// TODO: Remove this once useDevices is refactored
export const INITIALIZING = 'initializing';
export const NON_COMPLIANT = 'non_compliant';
export const PENDING = 'pending';
export const COMPLIANT = 'compliant';
export const NEEDS_REBOOT = 'needs_reboot';
export const EXCEPTION = 'exception';
export const UP_TO_DATE = 'up_to_date';
export const UNMANAGED = 'unmanaged';
export const CONNECTED = 'connected';

/* Helpers */
const threshold = 1024;

export function humanizeFileSize(bytes = 0): string {
  if (bytes < threshold) {
    return `${bytes} B`;
  }
  const i = Math.floor(Math.log(bytes) / Math.log(threshold));
  const num = bytes / 1024 ** i;
  const size = Math.round(num);

  return `${size} ${'KMGTPEZY'[i - 1]}B`;
}
