import { BespokeHTTPClient } from '@ax/data-services-common/client';

/**
 * This is a special one-off bespoke endpoint not specific to a particular account ID.
 * It is used when creating a new user to silently check if an entered email address already exists.
 * It will not display our global loading indicator nor will it show any toast messages
 * in the event of an error response.
 */

export function checkUserByEmail(email: string): Promise<boolean> {
  return BespokeHTTPClient.post(
    `/users/emails/active`,
    {
      email,
    },
    {
      loaderEnabled: false,
    },
  )
    .then(() => {
      return true;
    })
    .catch((error) => {
      // A 404 error is expected when email is not in use
      if (error.response.status === 404) {
        return false;
      }
      // Otherwise throw the error
      throw error;
    });
}
