export const combineText = (arrayText: string[] = []): string => {
  if (arrayText.length) {
    const words = arrayText;
    const last = words.pop();

    let conjunction;

    switch (words.length) {
      case 0:
        conjunction = '';
        break;
      case 1:
        conjunction = ' and ';
        break;
      default:
        conjunction = ', and ';
        break;
    }

    return `${words.join(', ')}${conjunction}${last}`;
  }

  return '';
};

export function capitalize(s: string): string {
  return s.charAt(0).toLocaleUpperCase() + s.slice(1);
}

export function snakeToReadable(text: string): string {
  return capitalize(
    text.replace(/_./g, (match) => ` ${match.charAt(1).toLocaleUpperCase()}`),
  );
}

export function kebabToReadable(text: string): string {
  return capitalize(
    text.replace(/-./g, (match) => ` ${match.charAt(1).toLocaleUpperCase()}`),
  );
}

/**
 * Takes an array of file type and makes it readable
 */
export function getFileTypesReadable(types: string[]): string {
  return types
    .filter((type) => type.charAt(0) === '.')
    .map((type) => type.slice(1).toLocaleUpperCase())
    .join(', ');
}

/**
 * Takes a size (in MB) and converts it to readable. Supports up to GB.
 */
export function getFileSizeReadable(size: number): string {
  if (size < 1000) {
    return `${size}MB`;
  }

  return `${Math.round(size / 100) / 10}GB`;
}
