export function wholeNumbersOnly(event: KeyboardEvent) {
  const charCode = event.which ? event.which : event.keyCode;
  if (
    (charCode > 40 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 96 || charCode > 105)) ||
    charCode === 32 || // ignore (space)
    charCode === 46 // ignore period
  ) {
    event.preventDefault();
  }
}

export function wholeNumberKeypress(event: KeyboardEvent): boolean {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    event.preventDefault();
    return false;
  }
  return true;
}
