import { AxiosResponse } from 'axios';
import { OrgTfa, UserTfa } from '@/models/tfa';
import httpClient from './http-client';

const TFA_ENDPOINT = '/tfa';

/**
 * @deprecated API will not function after Auth0 Milestone 1 launches
 */
export const getTFAImage = (): Promise<string> => {
  return httpClient.get('/googletfa').then((response) => response.data);
};

/**
 * @deprecated API will not function after Auth0 Milestone 1 launches
 */
export const checkTFACode = (tfa_code: string): Promise<string> => {
  return httpClient
    .post('/googletfa', { tfa_code })
    .then((response) => response.data);
};

/**
 * @deprecated API will not function after Auth0 Milestone 1 launches
 */
export const getOrgTfa = (orgId: string | number): Promise<OrgTfa> => {
  return httpClient
    .get(`/orgs/${orgId}${TFA_ENDPOINT}?o=${orgId}`)
    .then((response) => response.data);
};

/**
 * @deprecated API will not function after Auth0 Milestone 1 launches
 */
export const postOrgTfa = (
  orgId: string | number,
  require_tfa: boolean,
): Promise<AxiosResponse> => {
  return httpClient.post(`/orgs/${orgId}${TFA_ENDPOINT}?o=${orgId}`, {
    require_tfa,
  });
};

/**
 * @deprecated API will not function after Auth0 Milestone 1 launches
 */
export const putUserTfa = (
  userId: number,
  tfa_type: string,
  token?: string,
): Promise<AxiosResponse> => {
  return httpClient.put(`/users/${userId}${TFA_ENDPOINT}`, { tfa_type, token });
};

/**
 * @deprecated API will not function after Auth0 Milestone 1 launches
 */
export const getUserTfa = (userId: number): Promise<UserTfa> => {
  return httpClient
    .get(`/users/${userId}${TFA_ENDPOINT}`)
    .then((response) => response.data);
};

/**
 * @deprecated API will not function after Auth0 Milestone 1 launches
 */
export const triggerTfaEmail = (): Promise<AxiosResponse> => {
  return httpClient.post('/verify-remove-email-tfa');
};
